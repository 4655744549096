/**
 * Created by desarrollo on 31/08/18.
 */
jQuery(document).ready(function() {
    "use strict";
    var xhr;


    $('.owl-carousel-offer').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                items: 1,
                nav: true,
                navText: [
                    "<i class='fa fa-long-arrow-left'></i>",
                    "<i class='fa fa-long-arrow-right'></i>"
                ]
            },
            768:{
                loop: false,
                items:4,
                nav: true,
                navText: [
                    "<i class='fa fa-long-arrow-left'></i>",
                    "<i class='fa fa-long-arrow-right'></i>"
                ]
            },
            1024:{
                loop: false,
                items:2,
                nav: true,
                navText: [
                    "<img class='icon-control left' src='../images/icons/icon-arrow-tables-left.png'>",
                    "<img class='icon-control right' src='../images/icons/icon-arrow-tables.png'>"
                ]
            }
        }
    });
    $('.owl-carousel').each(function(){

        var owl_defaults = {
            items: 1,
            loop: true,
            center: false,
            rewind: true,

            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
            freeDrag: false,

            margin: 0,
            stagePadding: 0,

            merge: false,
            mergeFit: true,
            autoWidth: false,

            startPosition: 0,
            rtl: false,

            smartSpeed: 250,
            fluidSpeed: false,
            dragEndSpeed: false,

            responsiveRefreshRate: 200,
            responsiveBaseElement: window,

            fallbackEasing: 'swing',

            info: false,

            nestedItemSelector: false,
            itemElement: 'div',
            stageElement: 'div',

            refreshClass: 'owl-refresh',
            loadedClass: 'owl-loaded',
            loadingClass: 'owl-loading',
            rtlClass: 'owl-rtl',
            responsiveClass: 'owl-responsive',
            dragClass: 'owl-drag',
            itemClass: 'owl-item',
            stageClass: 'owl-stage',
            stageOuterClass: 'owl-stage-outer',
            grabClass: 'owl-grab',
            nav: false,
            navText: [
                "<i class='fa fa-caret-left'></i>",
                "<i class='fa fa-caret-right'></i>"
            ]
        };

        var $carousel = $(this);


        //console.log($.extend({}, $carousel.data(), owl_defaults));

        if($carousel.data().hasOwnProperty('owlconfig')){
            $carousel.owlCarousel( $.extend({}, owl_defaults, $carousel.data().owlconfig) );
        }else{
            $carousel.owlCarousel( $.extend({}, owl_defaults, $carousel.data()) );
        }

    });

    $('.open-panel').on('click', function(){
        console.log('hola');
        $('.template1517').toggleClass('open-1');
        //$('.menu-container ').addClass('open-1');
        //$('#canvas').addClass('open-1');
    });

    $('#myTabs a').click(function (e) {
        e.preventDefault()
        $(this).tab('show')
    });
    $(document).on('click', '.icon-act', function(e){
        e.preventDefault();
        var action = $(this).data('action');
        var $box_all = $(this).parents('.bx-item');
        var $contain = $box_all.find('.bx-contain');
        var $box = $contain.find('.item-bx.hidden');

        if(action=='plus'){
            var $new_box = $box.clone();
            $new_box.removeClass('hidden');
            $new_box.find('.datepicker').datepicker({
                dateFormat: "dd M yy"
            });


            $.each($new_box.find('.item-copy'), function(index, item){
                var old_name = $(item).attr('name')
                //console.log('old_name', old_name);
                old_name = old_name.replace(/0/g, $contain.find('.item-bx').length);
                //console.log('old_name', old_name, $contain.find('.item-bx').length);

                $(item).attr('name', old_name)
            });

            $contain.append($new_box);
        }else if(action=='minus'){
            console.log('minus');
            $box_all.find('.bx-contain .item-bx:last-child').not('.no-delete').remove();

        }
    });


    var form_helper = function($array) {
        var is_names= '';
        var value= '';
        var values= [];
        var array_n = [];
        $.each($($array), function (index2, item) {
            is_names = $(item).attr('name');
            value = $(item).val();
            values[is_names]=value;
            //rray_n[index2]=values;
        });

        return values;
    };

    $('#form-guest .btn-guest').on('click', function(e){
        console.log('crear');
        e.preventDefault();

        var datas = [];
        $.each($('.form-item').not('.hidden'), function (index, item) {
            //datas[index]= form_helper($(item).find('.form-elem'));
            datas[index]= $(item).serializeArray();

        });

        console.log(datas);

        var $form = $(this).parents('#form-guest');
        var route = $form.data('route');
        var route_now = $form.data('route_now');
        var $box_error = $form.find('.request-post');


        $form.find('input').removeClass('input-error');
        var id          =   $form.find('input[name=_id]').val();
        var _token      =   $form.find('input[name=_token]').val();

        xhr =   $.ajax({
            type:"post",
            url: route,
            dataType: 'json',
            data: {

               datas:datas,
                id:id,
                '_token':_token
            },
            beforeSend: function() {
                //$(".loading-ajax").css('display', 'block');
                //$(".loading-ajax").html("<p align='center'><img src='/img/back/ajax-loader.gif' /></p>");
            },
            error: function (response) {

             /*   var contain = '';

                console.log('index', response);
                $.each(response.responseJSON.errors, function(index, value){
                    console.log('index', value);

                    $form.find('input[name='+index+']').addClass('input-error');

                    if(contain==''){
                        contain = value;
                    }

                });*/
                //var contain = 'Llena los campos requeridos';


                //response_ajax_form('error', contain, $box_error);
            },
            success: function (response) {
                //console.log(response, 'response');
                /*if(response.status=='error'){
                    if($form.hasClass('desktop')){
                        var contain = response.text;
                    }
                    ///response_ajax('error', contain);
                    response_ajax_form('error', contain, $box_error);
                }else*/ if(response.type=='success'){
                    window.location = response.route;
                }

            }
        });

    });

    var response_ajax_form = function(type, contain, $box_error) {

        var output = '<label>'+contain+'</label>';
        $box_error.addClass('box-'+type);
        $box_error.html(output);
    };

    $('#search_reservation .btn-send').on('click', function(e){
        e.preventDefault();

        var $form = $(this).parents('#search_reservation');
        var route = $form.data('route');
        var route_now = $form.data('route_now');
        var $box_error = $form.find('.request-post');
        var datas = $form.serializeArray();


        $form.find('input').removeClass('input-error');
        var _token      =   $form.find('input[name=_token]').val();

        xhr =   $.ajax({
            type:"post",
            url: route,
            data: datas,
            beforeSend: function() {
                //$(".loading-ajax").css('display', 'block');
                //$(".loading-ajax").html("<p align='center'><img src='/img/back/ajax-loader.gif' /></p>");
            },
            error: function (response) {
                var contain = '';

                $.each(response.responseJSON.errors, function(index, value){
                     //console.log('index', value);

                     $form.find('input[name='+index+']').addClass('input-error');
                     if(contain==''){
                        contain = value;
                     }

                 });

                response_ajax_form('error', contain, $box_error);
            },
            success: function (response) {
                console.log(response.data, 'response');
                var contain = response.message;
                if(response.type=='success'){
                    $('.table.reservations tbody').append(response.data);
                    response_ajax_form('success', contain, $box_error);

                }else{
                    response_ajax_form('error', contain, $box_error);
                }
                 /*if(response.status=='error'){
                 if($form.hasClass('desktop')){
                 }
                 ///response_ajax('error', contain);
                 response_ajax_form('error', contain, $box_error);
                 }else if(response.status=='success'){
                 window.location = response.route;
                 }
                 */
            }
        });

    });


    $(document).on('click', '.icon-guest', function(e){
        e.preventDefault();
        var action = $(this).data('action');
        var $box_all = $(this).parents('.nav-header');
        var $contain = $box_all.find('.nav-tabs');
        var $box = $contain.find('li.hidden');
        var $form =  $('#form-guest');
        var $tab = $form.find('.tab-pane:first-child').clone().removeClass('hidden');
        $tab.find('.form-item').removeClass('hidden');

        if(action=='plus'){
            var $new_box = $box.clone();
            $new_box.removeClass('hidden');
            //$new_box.find('.item-tab').attr('id', 'guest'+$contain.find('li').length);
            $new_box.find('.item-tab').attr('href', '#guest'+$contain.find('li').length);
            $new_box.find('a span').text($contain.find('li').length);
            $tab.attr('id', 'guest'+$contain.find('li').length);
            $('.tab-content').append($tab);

            $tab.find('.datePicker').datepicker({
                dateFormat: "dd M yy"
            });

            $contain.append($new_box);
        }else if(action=='minus'){
            console.log('minus');
            $box_all.find('.bx-contain .item-bx:last-child').not('.no-delete').remove();

        }
    });


});